import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const TrainingPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Formation FishmedNet</h2>
    <p>
      Dans le cadre des activités menées par le projet FISHMEDNET, la formation est un outil clé pour améliorer les compétences des entreprises et développer de nouvelles opportunités de diversification des activités de pêche.
    </p>
    <p>
      À travers 4 modules de formation, chacun dédié à un thème spécifique, les experts interrogés soulignent les défis et les opportunités de la diversification des activités, tout en fournissant des témoignages de bonnes pratiques déjà présentes dans le contexte méditerranéen.
    </p>
    <p>
      <a href="https://www.youtube.com/@fishmednetenicbcmed/playlists">https://www.youtube.com/@fishmednetenicbcmed/playlists</a>
    </p>

    <h3>Tourisme</h3>
    <br/>
    <strong>Du itti-tourisme au pêche-tourisme, toutes les étapes nécessaires pour démarrer une nouvelle activité.</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/TuVYx08lnuw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Acquaculture</h3>
    <br/>
    <strong>Quelles sont les opportunités et les contraintes du secteur, ainsi que les étapes pour démarrer et gérer une activité d'aquaculture?</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/atxbvtNIfC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Alimentation</h3>
    <br/>
    <strong>Une introduction aux principaux processus de transformation des produits aquatiques et aux risques qui leur sont associés.</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/cC22oCAIKxM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Service à la communauté</h3>
    <br/>
    <strong>Un aperçu des différents services environnementaux liés à la diversification de la pêche.</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/vCipmvbpYbQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h2>Explorez parmi les dernières propositions</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default TrainingPage
